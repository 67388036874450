import { graphql, navigate } from "gatsby";
import React, { useEffect } from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";

const NotFoundPage: React.FC = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    navigate(`/${i18n.language}/`, { replace: true });
  }, []);

  return null;
};

export default NotFoundPage;

export const query = graphql`
  query ($language: String!) {
    ...TranslationQueryFragment
  }
`;
